// @flow
import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

import myImg1 from './images/product1.png';
import myImg2 from './images/product2.png';
import myImg3 from './images/product3.png';
import myImg4 from './images/product4.png';
import myImg5 from './images/product5.png';

const styles = theme => ({
  card: {
    width: "100%",
  },
  media: {
    maxWidth: "100%",
    height: "auto",
    paddingTop: '5%',
    paddingBottom: '3%',
  },
  mainText: {
    paddingTop: 30,
    marginBottom: 30,
    textAlign: "center",
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: "10%",
  },
  container2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  imgContainer: {
    flex: 1,
  },
  media2: {
    maxWidth: '100%',
    height: "auto",
    paddingLeft: '8%',
    paddingRight: '8%',
    paddingTop: '5%',
    paddingBottom: '3%',
  },
  textContainer: {
    flex: 1,
    textAlign: 'left',
    width: "100%",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  title: {
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1.1,
    color: '#16103c',
    paddingTop: 30,
    paddingBottom: 30,
  },
  details: {
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 18,
    lineHeight: 1.37,
    color: '#353434',
  },
  details2: {
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 18,
    lineHeight: 1.37,
    color: '#353434',
    maxWidth: "80%"
  }
});

class Product extends Component {

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <div style={{backgroundColor: "#fffefe"}}>

        <div className={classes.container}>
          <div className={classes.imgContainer}>
            <img
              src={myImg4}
              className={classes.media2}
              alt={myImg4}
              width="3000"
              height="1920"
            />
          </div>

          <div className={classes.textContainer}>
            <div className={classes.title}>
              Make artificial<br/>neural networks
            </div>
            <div className={classes.details2} >
              Atlas needs your help to be repaired. Learn to make
              artificial neural networks to make Atlas smart again. 
            </div>
          </div>
        </div>

        <div className={classes.mainText}>
          <div className={classes.title}>
            Easy and fun
          </div>
          <div className={classes.details}>
            Learn to code Atlas, your own artificial intelligence. Atlas is a<br/>
            Neurobot from the planet Leran. He crashed on earth from space.
          </div>
        </div>

        <img
          src={myImg1}
          className={classes.media}
          alt={myImg1}
          width="3000"
          height="1920"
        />

        <div className={classes.container2}>
          <div className={classes.textContainer}>
            <div className={classes.title}>
              Meet Poly and Atlas
            </div>
            <div className={classes.details2}>
              The Animoïds and the Neurobots are living together on Leran.
              Atlas and Poly will share their crazy adventure with you.
            </div>
          </div>

          <div className={classes.imgContainer}>
            <img
              src={myImg2}
              className={classes.media2}
              alt={myImg2}
              width="3000"
              height="1920"
            />
          </div>
        </div>

        <img
          src={myImg3}
          className={classes.media}
          alt={myImg3}
          width="3000"
          height="1920"
        />

        <div className={classes.container}>
          <div className={classes.imgContainer}>
            <img
              src={myImg5}
              className={classes.media2}
              alt={myImg5}
              width="3000"
              height="1920"
            />
          </div>

          <div className={classes.textContainer}>
            <div className={classes.title}>
              Matchmaking
            </div>
            <div className={classes.details2} >
              Competitive match-ups allow kids to share their learning with their friends.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Product);
