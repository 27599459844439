// @flow
import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    width: "100%",
  },
  media: {
    maxWidth: "100%",
    height: "auto",
    marginTop: 15
  },
  title: {
    position: 'relative',
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1.48,
    textAlign: 'center',
    color: '#16103c',
    paddingBottom: 20,
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 18,
    lineHeight: 1.37,
    textAlign: "center",
    paddingTop: 20,
  },
  detailsLongSpace: {
    flex: 11,
  },
  detailsShortSpace: {
    flex: 2,
  },
  detailsLeft: {
    flex: 38,
    color: "#ffffff",
    backgroundColor: "#fe4365",
  },
  detailsRight: {
    flex: 38,
    color: "#353434",
    backgroundColor: "#ffebee",
  },
  details: {
    paddingTop: 75,
    paddingBottom: 75,
    paddingLeft: 50,
    paddingRight: 50,
  },
});

class Project extends Component {

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <div style={{backgroundColor: "#FFFEFE"}}>

        <div className={classes.title}>
          Machine Learning is the future
        </div>

        <div className={classes.detailsContainer}>

          <div className={classes.detailsLongSpace}></div>
          <div className={classes.detailsLeft}>
            <div className={classes.details}>
              Poly is an Animoïd from the planet Leran. 
              He will teach you how to repair Atlas.<br/>
              <br/>
              <br/>
              Atlas has crashed on earth with secrets in him.
              You have to help Poly rebuild Atlas artificial mind to discover why they were attacked on the moon. 
            </div>
          </div>
          <div className={classes.detailsShortSpace}></div>
          <div className={classes.detailsRight}>
            <div className={classes.details}>
              Machine Learning (ML) is a form of artificial intelligence (AI) that allows computers to learn without being explicitly programmed.<br/>
              <br/>
              <br/>
              Instead of telling a computer everything he needs to know to do his job, the ML allows him to find the solution for himself.
            </div>
          </div>
          <div className={classes.detailsLongSpace}></div>
        </div>

      </div>
        
    );
  }
}

export default withStyles(styles)(Project);
