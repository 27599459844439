// @flow
import React, { Component } from "react";
import AWS from "aws-sdk"
import { withStyles } from '@material-ui/core/styles';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Dialogue from './Dialogue';

import btnSend from './images/buttonSend.png';
import vltBg from './images/violetBackground.png';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: "#fffefe",
  },
  title: {
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1.48,
    textAlign: 'left',
    color: '#16103c',
    marginLeft: "10%",
    paddingBottom: 40
  },
  violetBackground: {
    backgroundImage: `url(${vltBg})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "relative",
    backgroundPosition: "0% 120%",
    width: "100%",
  },
  paper: {
    margin: "auto",
    width: "73%",
    padding: "10px",
    borderRadius: "20px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#ffffff",
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: `${theme.spacing.unit * 3}px`
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    color: "red",
  },
  textBox: {
    position: "relative",
    marginLeft: "10%",
    marginRight: "0%",
    height: "100%",
  },
  textTitle: {
    paddingTop: '20%',
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 30,
    lineHeight: "1.34",
    textAlign: "left",
    color: "#16103c",
  },
  textDetails: {
    opacity: "0.5",
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 18,
    lineHeight: 1.2,
    textAlign: "left",
    color: "#16103c",
  },
  image: {
    position: "absolute",
    bottom: 0,
    left: 0,
    borderRadius: "5px",

    width: "calc(294px * 0.6)",
    height: "calc(116px * 0.6)",
    backgroundImage: `url(${btnSend})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0)",
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
    },
    transition: theme.transitions.create(
      ['box-shadow', 'transform'],
      { duration: theme.transitions.duration.short }
    )
  },
});

AWS.config.update({
    accessKeyId: 'AKIAJZASVQ5ZBTRHR2DQ',
    secretAccessKey: '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',
    region: 'us-west-2'
  });

const ses = new AWS.SES({ apiVersion: "2010-12-01" });

class SendEmail extends Component {

  constructor() {
    super()
    this.state = {
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      subject: "",
      subjectError: false,
      message: "",
      messageError: false,

      openChild: false,
      emailSent: true, 
    }
    this.handleChange  = this.handleChange.bind(this);
    this.handleOpen    = this.handleOpen.bind(this);
    this.sendEmailFunc = this.sendEmailFunc.bind(this);
  }

  handleChange = input => event => {
    this.setState({ [input]: event.target.value });

    const inputError = input + "Error";
    if (event.target.value !== "" && this.state[inputError] === true) {
      this.setState({ [inputError]: false });
    }
  };

  handleOpen(data) {
    this.setState({ openChild: data });
  }

  sendEmailFunc = () => {
    this.setState({ 'nameError':    (this.state.name    === "")})
    this.setState({ 'emailError':   (this.state.email   === "")})
    this.setState({ 'subjectError': (this.state.subject === "")})
    this.setState({ 'messageError': (this.state.message === "")})

    if (this.state.name === "" || this.state.email === "" || this.state.subject === "" || this.state.message === "") {
      return;
    }

    var tmpSentence = this.state.message;
    var sentences   = tmpSentence.replace("\n", "<br />");
    while (sentences !== tmpSentence) {
      tmpSentence = sentences;
      sentences = tmpSentence.replace("\n", "<br />")
    }

    var params = {
      Destination: {
        ToAddresses: ["contact@polygonewild.com"] // Email address/addresses that you want to send your email
      },
      Message: {
        Body: {
          Html: {
            // HTML Format of the email
            Charset: "UTF-8",
            Data:
              "<html><body>"+
                "<h1>Mail from : " + this.state.name + "</h1>" +
                "<h2>Email : " + this.state.email + "</h2>" +
                "<p>" + sentences + "</p>" +
                "</body></html>"
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: this.state.subject
        }
      },
      Source: "contact@polygonewild.com"
    };

    const sendEmail = ses.sendEmail(params).promise();

    sendEmail
      .then(data => {
        console.log("email submitted to SES", data);
        this.setState({ openChild: true, emailSent: true});
      })
      .catch(error => {
        console.log(error);
        this.setState({ openChild: true, emailSent: false });
      });
  }

  render() {

    const {
      classes}
      = this.props;

    return (
      <div className={classes.root}>
        
        {this.state.openChild && <Dialogue funOpen={this.handleOpen} emailSent={this.state.emailSent}/>}

        <Typography className={classes.title}>
          Keep in touch
        </Typography>

        <div className={classes.violetBackground}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container direction="row" >
              <Grid item xs={7}>
                <Grid container direction="column" >
                  <TextField
                    required
                    label="Name"
                    className={classes.textField}
                    value={this.state.name}
                    error={this.state.nameError}
                    onChange={this.handleChange('name')}
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    required
                    label="Email"
                    className={classes.textField}
                    value={this.state.email}
                    error={this.state.emailError}
                    onChange={this.handleChange('email')}
                    type="email"
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    required
                    label="Subject"
                    className={classes.textField}
                    value={this.state.subject}
                    error={this.state.subjectError}
                    onChange={this.handleChange('subject')}
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    required
                    label="Message"
                    multiline
                    rows="12"
                    className={classes.textField}
                    value={this.state.message}
                    error={this.state.messageError}
                    onChange={this.handleChange('message')}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid item xs={5}>
                <div className={classes.textBox}>
                  <div className={classes.textTitle}>
                    Interested in working together ?
                  </div>

                  <div className={classes.textDetails}>
                    <br />
                    +33 (0) 764 490 353<br />
                    <br />
                    contact@polygonewild.com<br />
                    <br />
                    8 boulevard Rodin<br />
                    92130 Issy-les-Moulineaux<br />
                    FRANCE
                  </div>

                  <ButtonBase
                    className={classes.image}
                    onClick={this.sendEmailFunc}
                  />
                </div>
              </Grid>
              
            </Grid>
          </Paper>
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(SendEmail);

/*const AWS = require("aws-sdk");

AWS.config.update({
    accessKeyId: 'AKIAJZASVQ5ZBTRHR2DQ',
    secretAccessKey: '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',
    region: 'us-west-2'
  });

const ses = new AWS.SES({ apiVersion: "2010-12-01" });
const params = {
  Destination: {
    ToAddresses: ["charles.bodin@polygonewild.com"] // Email address/addresses that you want to send your email
  },
  Message: {
    Body: {
      Html: {
        // HTML Format of the email
        Charset: "UTF-8",
        Data:
          "<html><body><h1>Hello  Charith</h1><p style='color:red'>Sample description</p> <p>Time 1517831318946</p></body></html>"
      },
      Text: {
        Charset: "UTF-8",
        Data: "Hello Charith Sample description time 1517831318946"
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: "Test email"
    }
  },
  Source: "charles.bodin@polygonewild.com"
};

const sendEmail = ses.sendEmail(params).promise();

sendEmail
  .then(data => {
    console.log("email submitted to SES", data);
  })
  .catch(error => {
    console.log(error);
  });*/

