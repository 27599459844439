// @flow
import React, { Component, Fragment } from "react";
import AWS from "aws-sdk"

import { withStyles }    from '@material-ui/core/styles';
import AppBar            from '@material-ui/core/AppBar';
import Toolbar           from '@material-ui/core/Toolbar';
import IconButton        from '@material-ui/core/IconButton';
import Button            from '@material-ui/core/Button';
import TextField         from '@material-ui/core/TextField';
import Dialog            from '@material-ui/core/Dialog';
import DialogActions     from '@material-ui/core/DialogActions';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle       from '@material-ui/core/DialogTitle';

import Welcome   from './Welcome'
import Product   from './Product'
import Project   from './Project'
import Team      from './Team'
import SendEmail from './SendEmail'
import Footer    from './Footer'
import Dialogue  from './Dialogue';

import myImg from './images/logo.png'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  marginLeft: {
    flex: 1,
    marginLeft: '2%',
  },
  marginRight: {
    flex: 1,
    marginRight: '2%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  ref: {
    textDecoration: "none"
  },
  media: {
    maxWidth: "150px",
    height: "auto",
    verticalAlign: 'middle',
    marginTop: 10,
    marginBottom: 10,
  },
  preOrder: {
    borderRadius: '43px',
    backgroundColor: '#fe4365',
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 'small',
    lineHeight: 1.15,
    textAlign: 'center',
    color: '#ffffff',
    
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0)",
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: '#fe4365',
      color: '#ffffff',
    },
  },

  Welcome: {
    backgroundImage: "url('http://www.polygonewild.com/images/home.png')",
    backgroundSize: "cover",
    height: "auto",
  },
});

const hrefList = [
  'Welcome',
  'Product',
  'Project',
  'Team',
  'Contact'
]

AWS.config.update({
  accessKeyId: 'AKIAJZASVQ5ZBTRHR2DQ',
  secretAccessKey: '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',
  region: 'us-west-2'
});

const ses = new AWS.SES({ apiVersion: "2010-12-01" });

class Home extends Component {

  constructor() {
    super()
    this.state = {
      hrefStates: [],

      openPreOrder: false,
      emailPreOrder: '',
      emailError: false,

      openChild: false,
      emailSent: true, 
    }

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleOpen      = this.handleOpen.bind(this);
    this.handleClose     = this.handleClose.bind(this);
    this.sendEmailFunc   = this.sendEmailFunc.bind(this);
    this.handleChange    = this.handleChange.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ openPreOrder: true });
  };

  sendEmailFunc = () => {
    this.setState({ openPreOrder: false });

    this.setState({ 'emailError':    (this.state.emailPreOrder === "")})
  
    if (this.state.emailPreOrder === "") {
      return;
    }

    var params = {
      Destination: {
        ToAddresses: ["contact@polygonewild.com"] // Email address/addresses that you want to send your email
      },
      Message: {
        Body: {
          Html: {
            // HTML Format of the email
            Charset: "UTF-8",
            Data:
              "<html><body>"+
                "<h1>Mail from : Pre-order</h1>" +
                "<h2>Email : " + this.state.emailPreOrder + "</h2>" +
                "</body></html>"
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: 'PRE-ORDER'
        }
      },
      Source: "contact@polygonewild.com"
    };

    const sendEmail = ses.sendEmail(params).promise();

    sendEmail
      .then(data => {
        console.log("email submitted to SES", data);
        this.setState({ openChild: true, emailSent: true});
      })
      .catch(error => {
        console.log(error);
        this.setState({ openChild: true, emailSent: false });
      });
  };

  handleClose = () => {
    this.setState({ openPreOrder: false });
  };

  handleOpen(data) {
    this.setState({ openChild: data });
  }

  handleChange = () => event => {
    this.setState({ emailPreOrder: event.target.value });
  };

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <Fragment>

        <div className={classes.root} ref={this.paneDidMount}>
          <AppBar position="fixed" color="white">
            <Toolbar variant="dense">

              <div className={classes.marginLeft}>
                <a href={"#"+hrefList[0]} className={classes.ref}>
                  <img
                    src={myImg}
                    className={classes.media}
                    id={hrefList[0]+'Button'}
                    alt="we are bringing a crazy story to you"
                    width="300"
                    height="58"
                  />
                </a>
              </div>

              <div className={classes.marginRight}>
                <IconButton className={classes.preOrder} onClick={this.handleClickOpen}>
                  PRE-ORDER
                </IconButton>
              </div>

              
            </Toolbar>
          </AppBar>
        </div>

        <div id="Welcome" style={{height: 58, backgroundColor: "#fffefe"}}></div>
        <Welcome gotoContact={"#"+hrefList[4]}/>
        <div id="Product"></div>
        <Product/>
        <div id="Project"></div>
        <Project/>
        <div id="Team"></div>
        <Team/>
        <div id="Contact"></div> 
        <SendEmail/>
        <Footer />

        {this.state.openChild && <Dialogue funOpen={this.handleOpen} emailSent={this.state.emailSent}/>}

        <Dialog
          open={this.state.openPreOrder}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">COMING SOON</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Be the first to know when you can get a Neurobot !
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              autoComplete="email"
              error={this.state.emailError}
              fullWidth
              value={this.state.emailPreOrder}
              onChange={this.handleChange()}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.sendEmailFunc} color="primary">
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Home);
