// @flow
import React, { Component } from "react";
import AWS from "aws-sdk"
import { withStyles } from '@material-ui/core/styles';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Dialogue from './Dialogue';

import btnSend from './images/buttonSend.png';
import vltBg from './images/violetBackground.png';

const styles = theme => ({
  mainContainer: {
    width: '100%',
    backgroundColor: "#FFFFFF",
  },
  title: {
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 'x-large',
    fontWeight: 'bold',
    lineHeight: 1.48,
    textAlign: 'left',
    color: '#16103c',
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: 20,
    paddingBottom: 20,
  },
  violetBackground: {
    backgroundImage: `url(${vltBg})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "relative",
    backgroundPosition: "0% 120%",
    width: "100%",
  },
  paper: {
    margin: "auto",
    width: "90%",
    borderRadius: "20px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing.unit*2,
    marginRight: theme.spacing.unit*2,
    color: "red",
  },
  bottomBox: {
    display: "flex",
    flexDirection: "row",
  },
  buttonBox: {
    flex: 1,
  },
  buttonImg: {
    marginTop: theme.spacing.unit*2,
    marginLeft: theme.spacing.unit*2,
    borderRadius: "5px",

    width: 89,
    height: 35,
    backgroundImage: `url(${btnSend})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0)",
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
    },
    transition: theme.transitions.create(
      ['box-shadow', 'transform'],
      { duration: theme.transitions.duration.short }
    )
  },
  textDetails: {
    flex: 2,
    paddingBottom: "5%",
    opacity: "0.5",
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 'small',
    lineHeight: 1.2,
    textAlign: "left",
    color: "#16103c",
  },
});

AWS.config.update({
    accessKeyId: 'AKIAJZASVQ5ZBTRHR2DQ',
    secretAccessKey: '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',
    region: 'us-west-2'
  });

const ses = new AWS.SES({ apiVersion: "2010-12-01" });

class SendEmail extends Component {

  constructor() {
    super()
    this.state = {
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      subject: "",
      subjectError: false,
      message: "",
      messageError: false,

      openChild: false,
      emailSent: true, 
    }
    this.handleChange  = this.handleChange.bind(this);
    this.handleOpen    = this.handleOpen.bind(this);
    this.sendEmailFunc = this.sendEmailFunc.bind(this);
  }

  handleChange = input => event => {
    this.setState({ [input]: event.target.value });

    const inputError = input + "Error";
    if (event.target.value !== "" && this.state[inputError] === true) {
      this.setState({ [inputError]: false });
    }
  };

  handleOpen(data) {
    this.setState({ openChild: data });
  }

  sendEmailFunc = () => {
    this.setState({ 'nameError':    (this.state.name    === "")})
    this.setState({ 'emailError':   (this.state.email   === "")})
    this.setState({ 'subjectError': (this.state.subject === "")})
    this.setState({ 'messageError': (this.state.message === "")})

    if (this.state.name === "" || this.state.email === "" || this.state.subject === "" || this.state.message === "") {
      return;
    }

    var tmpSentence = this.state.message;
    var sentences   = tmpSentence.replace("\n", "<br />");
    while (sentences !== tmpSentence) {
      tmpSentence = sentences;
      sentences = tmpSentence.replace("\n", "<br />")
    }

    var params = {
      Destination: {
        ToAddresses: ["contact@polygonewild.com"] // Email address/addresses that you want to send your email
      },
      Message: {
        Body: {
          Html: {
            // HTML Format of the email
            Charset: "UTF-8",
            Data:
              "<html><body>"+
                "<h1>Mail from : " + this.state.name + "</h1>" +
                "<h2>Email : " + this.state.email + "</h2>" +
                "<p>" + sentences + "</p>" +
                "</body></html>"
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: this.state.subject
        }
      },
      Source: "contact@polygonewild.com"
    };

    const sendEmail = ses.sendEmail(params).promise();

    sendEmail
      .then(data => {
        console.log("email submitted to SES", data);
        this.setState({ openChild: true, emailSent: true});
      })
      .catch(error => {
        console.log(error);
        this.setState({ openChild: true, emailSent: false });
      });
  }

  render() {

    const {
      classes}
      = this.props;

    return (
      <div className={classes.mainContainer}>
        
        {this.state.openChild && <Dialogue funOpen={this.handleOpen} emailSent={this.state.emailSent}/>}

        <Typography className={classes.title}>
          Keep in touch
        </Typography>

        <div className={classes.violetBackground}>
          <Paper className={classes.paper} elevation={1}>

            <TextField
              required
              label="Name"
              className={classes.textField}
              value={this.state.name}
              error={this.state.nameError}
              onChange={this.handleChange('name')}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              label="Email"
              className={classes.textField}
              value={this.state.email}
              error={this.state.emailError}
              onChange={this.handleChange('email')}
              type="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              label="Subject"
              className={classes.textField}
              value={this.state.subject}
              error={this.state.subjectError}
              onChange={this.handleChange('subject')}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              label="Message"
              multiline
              rows="12"
              className={classes.textField}
              value={this.state.message}
              error={this.state.messageError}
              onChange={this.handleChange('message')}
              margin="normal"
              variant="outlined"
            />
            
            <div className={classes.bottomBox}>
              <div className={classes.buttonBox}>
                <ButtonBase
                  className={classes.buttonImg}
                  onClick={this.sendEmailFunc}
                  />
              </div>
              <div className={classes.textDetails}>
                <br />
                +33 (0) 764 490 353<br />
                <br />
                contact@polygonewild.com<br />
                <br />
                8 boulevard Rodin<br />
                92130 Issy-les-Moulineaux<br />
                FRANCE
              </div>
            </div>

          </Paper>
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(SendEmail);

/*const AWS = require("aws-sdk");

AWS.config.update({
    accessKeyId: 'AKIAJZASVQ5ZBTRHR2DQ',
    secretAccessKey: '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',
    region: 'us-west-2'
  });

const ses = new AWS.SES({ apiVersion: "2010-12-01" });
const params = {
  Destination: {
    ToAddresses: ["charles.bodin@polygonewild.com"] // Email address/addresses that you want to send your email
  },
  Message: {
    Body: {
      Html: {
        // HTML Format of the email
        Charset: "UTF-8",
        Data:
          "<html><body><h1>Hello  Charith</h1><p style='color:red'>Sample description</p> <p>Time 1517831318946</p></body></html>"
      },
      Text: {
        Charset: "UTF-8",
        Data: "Hello Charith Sample description time 1517831318946"
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: "Test email"
    }
  },
  Source: "charles.bodin@polygonewild.com"
};

const sendEmail = ses.sendEmail(params).promise();

sendEmail
  .then(data => {
    console.log("email submitted to SES", data);
  })
  .catch(error => {
    console.log(error);
  });*/

