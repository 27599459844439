// @flow
import React, { Component, Fragment } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Check from '@material-ui/icons/Check';
import Error from '@material-ui/icons/Error';

const errorCase = {
  backgroundColor: "rgb(200, 100, 100)",
  title: "An error occurs !",
  text: "You are not responsible. Please, use our email to contact us."
}

const sentCase = {
  backgroundColor: "rgb(250, 250, 250)",
  title: "Email sent to Poly Gone Wild !",
  text: "Thanks you !"
}

type Props = {
  funOpen: Function,
  emailSent: boolean
};

class Dialogue extends Component<Props> {
  props: Props;

  constructor(props) {
    super(props)
    this.state = {
      open: true
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.props.funOpen(false);
    this.setState({ open: false });
  };

  render() {

    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div>
            <DialogTitle id="responsive-dialog-title">
              <div style={{color: (this.props.emailSent ? 'primary' : errorCase.backgroundColor)}}>
                {(this.props.emailSent ? sentCase.title : errorCase.title)}
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {(this.props.emailSent ? sentCase.text : errorCase.text)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton  onClick={this.handleClose} color="primary">
                {(this.props.emailSent ? <Check fontSize="large" color='primary'/> : <Error fontSize="large" color='secondary'/>)}
              </IconButton >
            </DialogActions>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default withMobileDialog()(Dialogue);