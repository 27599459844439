// @flow
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import logo from './images/CES.png';

const styles = theme => ({
  mainContainer: {
    backgroundColor: "#0772b8",
    padding: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    fontFamily: "'Open Sans', 'sans-serif'",
    color: "#ffffff",
    fontSize: 14,
  },
  left: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  img: {
    width: "80%",
    height: "auto",
    paddingLeft: 20,
  },
  right: {
    flex: 3,
  },
  textp: {
    float: "right",
    paddingLeft: 40,
    paddingRight: 40,
  },
});

class Banner extends Component {

  render() {
    
    const {
      classes}
      = this.props;

    return (
      <div className={classes.mainContainer}>
        <div className={classes.left}>
          <img  className={classes.img}
            src={logo}
            alt={logo}
          />
        </div>
        <div className={classes.right}>
          <p className={classes.textp}>Join us on Booth n° 50882</p>
        </div>  
      </div>
    );
  }
}

export default withStyles(styles)(Banner);
