// @flow
import React, { Component, Fragment } from 'react';

import CssBaseline from "@material-ui/core/CssBaseline";

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import LaptopHome from './Laptop/Home';
import MobileHome from './Mobile/Home';

type Props = {
  width: Object
};

class App extends Component<Props> {
  props: Props;

  render() {

    //width = [xs, sm, md, lg, xl]
    const { width } = this.props;

    if (isWidthUp('sm', width)) {
      return (
        <Fragment>
          <CssBaseline />
          <LaptopHome/>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <CssBaseline />
        <MobileHome/>
      </Fragment>
    )
  }
}

export default withWidth()(App);