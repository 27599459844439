// @flow
import React, { Component, Fragment } from "react";

import { withStyles } from '@material-ui/core/styles';

import myImg1 from './images/product1.png';
import myImg2 from './images/product2.png';
import myImg3 from './images/product3.png';
import myImg4 from './images/product4.png';
import myImg5 from './images/product5.png';

const styles = theme => ({
  card: {
    width: "100%",
  },
  media: {
    maxWidth: "100%",
    height: "auto",
  },
  media2: {
    maxWidth: "100%",
    height: "auto",
    paddingLeft: '8%',
    paddingRight: '8%',
    paddingBottom: '9%',
    imageRendering: 'high-quality',
  },
  mainText: {
    paddingTop: 40,  
    paddingBottom: 40,
  },
  title: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingBottom: 20,
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 'x-large',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'left',
    color: '#16103c',
  },
  details: {
    paddingLeft: "11%",
    paddingRight: "11%",
    width: "867px",
    maxWidth: '100%',
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 'normal',
    lineHeight: 1.37,
    textAlign: 'left',
    color: '#353434',
  },
  textOnBlueBg: {
    paddingTop: 40,
    paddingBottom: 40,
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 'xx-large',
    fontWeight: "bold",
    fontStretch: "normal",
    lineHeight: "1.2",
    textAlign: "center",
    color: "#ffffff",
    backgroundColor: "#2b99b4",
  }
});

class Product extends Component {

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <Fragment>
        <div style={{backgroundColor: "#fffefe"}}>

          <div className={classes.mainText}>
            <div className={classes.title}>
              Make artificial<br/>neural network
            </div>
            <div className={classes.details}>
              Atlas needs your help to be repaired. Learn to make artificial neural networks to make Atlas smart again. 
            </div>
          </div>

          <img
            src={myImg5}
            className={classes.media2}
            alt={myImg5}
            width="3000"
            height="359"
          />

          <div className={classes.mainText}>
            <div className={classes.title}>
              Easy and fun
            </div>
            <div className={classes.details}>
              Learn to code Atlas, your own artificial intelligence. Atlas is a
              Neurobot from the planet Leran. He crashed on earth from space.
            </div>
          </div>

          <img
            src={myImg1}
            className={classes.media}
            alt={myImg1}
            width="3000"
            height="359"
          />

          <div className={classes.mainText}>
            <div className={classes.title}>
                Meet Poly and Atlas
            </div>
            <div className={classes.details}>
              The Animoïds and the Neurobots are living together on Leran.
              Atlas and Poly will share their crazy adventure with you.
            </div>
          </div>

          <img
            src={myImg2}
            className={classes.media2}
            alt={myImg2}
            width="3000"
            height="359"
          />

          <div className={classes.textOnBlueBg}>
            Learn to build AI<br/>
            Chat with Poly<br/>
            Play with friends
          </div>

          <img
            src={myImg3}
            className={classes.media}
            alt={myImg3}
            width="3000"
            height="359"
          />

          <div className={classes.mainText}>
            <div className={classes.title}>
              Matchmaking
            </div>
            <div className={classes.details} >
              Competitive match-ups allow kids to share their learning with their friends.
            </div>
          </div>

          <img
            src={myImg4}
            className={classes.media2}
            alt={myImg4}
            width="3000"
            height="359"
          />

        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Product);
