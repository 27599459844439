// @flow
import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  mainContainer: {
    width: "100%",
    padding: "1%",
  },
  media: {
    maxWidth: "65%",
    height: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 'x-small',
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "center",
    color: "#16103c",
    paddingTop: 7
  },
  details: {
    fontFamily: "'Open Sans', 'sans-serif'", 
    fontSize: "xx-small",
    lineHeight: 1.35,
    textAlign: "center",
    color: "#16103c",
  },
  lines: {
    borderTop : "1px solid #707070",
    width: "25%",
    margin: "auto",
    paddingBottom: 8,
  }
});

class TeamProfile extends Component<Props> {
  props: Props;

  render() {
    
    const {
      classes,
      img,
      title,
      details,
    } = this.props;
      
    return (
      <div className={classes.mainContainer}>
        <img
            src={img}
            className={classes.media}
            alt={img}
            width="151"
            height="151"
          />
        
        <div className={classes.title}>
          {title}
        </div>
        
        <div className={classes.lines}/>

        <div className={classes.details}>
          {details}<br/>OFFICER
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TeamProfile);
