// @flow
import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    width: "100%",
  },
  title: {
    position: 'relative',
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 'x-large',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'center',
    color: '#16103c',
    paddingBottom: 45,
  },
  detailsContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 'x-small',
    lineHeight: 1.37,
    textAlign: "center",
  },
  detailsLeft: {
    flex: 1,
    color: "#ffffff",
    backgroundColor: "#fe4365",
  },
  detailsRight: {
    flex: 1,
    color: "#353434",
    backgroundColor: "#ffebee",
  },
  details: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 25,
    paddingRight: 25,
  },
});

class Project extends Component {

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <div style={{backgroundColor: "#fffefe"}}>

        <div className={classes.title}>
          Machine Learning<br/>
          is the future
        </div>

        <div className={classes.detailsContainer}>
          <div className={classes.detailsLeft}>
            <div className={classes.details}>
            Poly is an Animoïd from the planet Leran. 
            He will teach you how to repair Atlas.<br/>
            <br/>
            <br/>
            Atlas has crashed on earth with secrets in him.
            You have to help Poly rebuild Atlas artificial mind to discover why they were attacked on the moon. 
            </div>
          </div>
          <div className={classes.detailsRight}>
            <div className={classes.details}>
              Machine Learning (ML) is a form of artificial intelligence (AI) that allows computers to learn without being explicitly programmed.<br/>
              <br/>
              <br/>
              Instead of telling a computer everything he needs to know to do his job, the ML allows him to find the solution for himself.
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(Project);
