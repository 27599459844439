// @flow
import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import Banner from './Banner'
import welcomeImg from './images/welcomeImg.png';

const styles = theme => ({
  mainContainer:{
    position: 'relative',
  },
  media: {
    verticalAlign: "middle",
    maxWidth: "100%",
    height: "auto",
  },
  mainTitle: {
    position: 'absolute',
    top: '15%',
    left: '5%',
    //transform: 'translate(-50%, -50%)',
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 1.49,
    textAlign: 'Left',
    color: '#ffffff',
    width: '100%',
    letterSpacing: -1
  },
  hrefPosition: {
    position: 'absolute',
    top: '68%',
    left: '12%',
    transform: 'translate(-50%, -50%)',
    textDecoration: "none",
  },
  contactBtn: {
    borderRadius: '43px',
    backgroundColor: '#fe4365',
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 'x-small',
    lineHeight: 1.35,
    textAlign: 'center',
    color: '#ffffff',
    transition: '0.1s',
    
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0)",
    '&:hover': {
      lineHeight: 1.355,
      fontSize: '18px',
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.20)",
      backgroundColor: '#fe4365',
      color: '#ffffff',
    },
  },
}); 

class Welcome extends Component {

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <div className={classes.mainContainer}>
        <img
          src={welcomeImg}
          className={classes.media}
          alt={welcomeImg}
        />
        
        <Banner/>

        <div className={classes.mainTitle}>
          Build your own<br/>Artificial<br/>Intelligence
        </div>

        <a href={this.props.gotoContact} className={classes.hrefPosition}>
          <IconButton className={classes.contactBtn} onClick={this.handleClickOpen}>
            CONTACT
          </IconButton>
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(Welcome);
