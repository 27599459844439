// @flow
import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import TeamProfile from "./TeamProfile";
import FRimg from './images/FlorianRenardCEO.png';
import CBimg from './images/CharlesBodinCTO.png';
import FMimg from './images/FlorentMellCDO.png';
import vltBg from './images/violetBackgroundTeam.png';

const styles = theme => ({
  paddingContainer:{
    paddingTop: 45,
    paddingBottom: 20,
    backgroundColor: '#FFFFFF',
  },
  mainContainer: {
    maxWidth: "100%",
    height: "auto",
    backgroundImage: `url(${vltBg})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "relative",
    backgroundPosition: "0% 0%",
    width: "100%",
  },
  title: {
    position: 'relative',
    fontFamily: "'M PLUS Rounded 1c', 'sans-serif'",
    fontSize: 'x-large',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'center',
    color: '#FFFFFF',
    paddingTop: 45,
    paddingBottom: 35,
  },
  paper: {
    margin: "auto",
    width: "90%",
    borderRadius: "20px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
    display: "flex",
    alignItems: "center"
  },
});

class Team extends Component {

  render() {
    
    const {
      classes}
      = this.props;
      
    return (
      <div className={classes.paddingContainer}>
        <div className={classes.mainContainer}>
          <div className={classes.title}>
            The founders
          </div> 

          <Paper className={classes.paper} elevation={1}>
            <TeamProfile
              className={classes.profileContainer}
              img={FRimg}
              title="FLORIAN RENARD"
              details="CHIEF EXECUTIVE"
              />
            <TeamProfile
              className={classes.profileContainer}
              img={CBimg}
              title="CHARLES BODIN"
              details="CHIEF TECHNOLOGY"
              />
          </Paper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Team);
