// @flow
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  text: {
    backgroundColor: "#2b99b4",
    padding: "5%",
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 'x-small',
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.32,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
  }
});

class Footer extends Component {

  render() {
    
    const {
      classes}
      = this.props;

    return (
      <div className={classes.text}>
        Phone number : +33 (0) 764 490 353<br />
        Head Quarter : 8, Boulevard Rodin, 92130 Issy-Les-Moulineaux FRANCE<br />
        contact@polygonewild.com<br /><br />
        POLY GONE WILD SAS Capital 10 000 €<br />
        Siret : 820721348 00010
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
